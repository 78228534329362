<template>
  <div>
    <div>
      <v-btn class="ma-1" dark small @click="showAddressDialog = true"
        ><v-icon small>mdi-plus-circle-outline</v-icon>
        {{ localizedText[language].addAddress }}</v-btn
      >
      <v-dialog max-width="500" v-model="showAddressDialog">
        <AddAddressComponent
          v-on:address-added="addressAdded($event)"
          v-on:deliver-to-address="deliverToThisAddress($event)"
        ></AddAddressComponent>
      </v-dialog>
      <v-dialog max-width="500" v-model="showEditAddressDialog">
        <EditAddressComponent
         :addressModel="editAddressModel"
          v-on:address-added="addressAdded($event)"
          v-on:deliver-to-address="deliverToThisAddress($event)"
        ></EditAddressComponent>
      </v-dialog>
    </div>
    <v-data-iterator
      
      :items="myAddresses"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      hide-default-footer
    >
      <template v-slot:default="props">
        <v-card v-for="address in props.items" :key="address.id" class="ma-1">
          <v-card-text class="d-flex pa-2 align-center">
            <div class="caption">
              {{ address.contactName }} - {{ address.area }} -
              {{ address.phone }}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="isCheckout"
              @click="deliverToThisAddress(address)"
              small
              outlined
            >
              <!--    <v-icon small> mdi-truck-delivery-outline </v-icon> -->
              {{ localizedText[language].deliverToThisAddress }}
            </v-btn>

            <v-btn small @click="editAddress(address)" icon>
              <v-icon small> mdi-pen </v-icon>
            </v-btn>
            <v-btn :loading="deleteLoadings[address.id]" small color="red" @click="deleteAddress(address)" icon>
              <v-icon small> mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
         
      </template>
      <template v-slot:footer>
        <v-card flat class="pa-2" v-if="numberOfPages > itemsPerPage">
          <span class="mr-4 grey--text">
            {{ localizedText[language].page }} {{ page }}
            {{ localizedText[language].of }} {{ numberOfPages }}
          </span>
          <v-btn icon class="mr-1" @click="formerPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn icon class="ml-1" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>

import AddAddressComponent from "@/components/AddAddressComponent";
import EditAddressComponent from "@/components/EditAddressComponent";
import { mapGetters } from "vuex";
export default {
  name: "AddressBookComponent",
  //********************** */
  props: {
    isCheckout: {
      type: Boolean,
    },
  },
  components: { AddAddressComponent , EditAddressComponent},
  //********************** */
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      currencyCode: "currencyCode",
      currency: "currency",
    }),

    ...mapGetters("Address", {
      allAddresses: "allAddresses",
      addresses: "addresses",
    }),
    numberOfPages() {
      return Math.ceil(this.myAddresses.length / this.itemsPerPage);
    },
  },
  //********************** */
  data: () => ({
    isLoading: false,
    page: 1,
    itemsPerPage: 10,
    myAddresses: [],
    deleteLoadings:{},
    localizedText: {
      en: {
        page: "Page",
        of: "of",
        addAddress: "Add address",
        deliverToThisAddress: "Deliver to this address",
      },
      ar: {
        page: "صفحة",
        of: "من",
        addAddress: "اضافة عنوان",
        deliverToThisAddress: "التوصيل لهذا العنوان",
      },
    },

    showAddressDialog: false,
    showEditAddressDialog: false,
    editAddressModel:null
  }),

  methods: {
    loadAddresses: async function () {
      try {
        this.isLoading = true;
        if (this.isCheckout) {
          await this.$store.dispatch("Address/loadAddresses");
          this.myAddresses = this.addresses;
        } else {
          await this.$store.dispatch("Address/loadAllAddresses");
          this.myAddresses = this.allAddresses;
        }
        this.prepareDeleteLoadings();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert(error);
      }
    },

    editAddress: function (address) {
      this.editAddressModel = address;
      this.showEditAddressDialog = true;
    },
    deleteAddress: async function (address) {
      let model = { addressId: address.id, language: this.language };
      try {
        this.deleteLoadings[`${address.id}`] = true;

        await this.$store.dispatch("Address/deleteAddresses", model);
        this.deleteLoadings[address.id] = false;
        if (this.isCheckout) {
          this.myAddresses = this.addresses;
        } else {
          this.myAddresses = this.allAddresses;
        }
         this.prepareDeleteLoadings();
      } catch (error) {
        alert(error);
      }
    },
    deliverToThisAddress: function (address) {
      this.$emit("deliver-to", address);
    },
    addressAdded: function (e) {
      this.showAddressDialog = false;
      this.showEditAddressDialog = false;
      if (this.isCheckout) {
        this.myAddresses = this.addresses;
      } else {
        this.myAddresses = this.allAddresses;
      }
      this.prepareDeleteLoadings();
    },
    prepareDeleteLoadings: function(){
      let dictionary = Object.assign({}, ...this.myAddresses.map((x) => ({[x.id]: false})));

      this.deleteLoadings = dictionary;

      console.log(dictionary);
    }
  },

  async mounted() {
    await this.loadAddresses();
  },
};
</script>

<style>
</style>