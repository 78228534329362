<template>
  <v-card>
    <div>
      <!--   <v-list-item dense v-if="!isGiftAndAddressUnkown">
        <v-list-item-content>
          <v-list-item-title>
            <v-text-field
              v-model="addressModel.name"
              :label="localizedText[language].addressName"
              :rules="[rules.required]"
              :hint="localizedText[language].addressNameHint"
            >
            </v-text-field>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <v-list-item dense>
        <v-list-item-content>
          <v-list-item-title>
            <v-row>
              <v-col>
                <v-text-field
                  prepend-icon="mdi-account"
                  v-model="addressModel.firstName"
                  :label="localizedText[language].firstName"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="addressModel.lastName"
                  :label="localizedText[language].lastName"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-subheader>{{ localizedText[language].contactInfo }}</v-subheader>
      <v-list-item dense>
        <v-list-item-content>
          <v-list-item-title>
            <v-text-field
              prepend-icon="mdi-email"
              v-model="addressModel.email"
              :label="localizedText[language].email"
              :rules="[rules.required]"
            >
            </v-text-field>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item dense>
        <v-list-item-content>
          <v-list-item-title>
            <v-text-field
              type="tel"
              dir="ltr"
              prepend-icon="mdi-phone"
              v-model="addressModel.phone"
              :prefix="language == 'en' ? country.dialCode.substring(1) : ''"
              :suffix="language == 'ar' ? country.dialCode.substring(1) : ''"
              :label="localizedText[language].phone"
              :rules="[rules.required, rules.numbeOfDigits]"
            >
            </v-text-field>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item dense>
        <v-list-item-content>
          <v-list-item-title>
            <v-text-field
              type="tel"
              dir="ltr"
              prepend-icon="mdi-phone"
              v-model="addressModel.secondPhone"
              :prefix="language == 'en' ? country.dialCode.substring(1) : ''"
              :suffix="language == 'ar' ? country.dialCode.substring(1) : ''"
              :label="localizedText[language].secondPhone"
            >
            </v-text-field>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-subheader>{{ localizedText[language].addressDetail }}</v-subheader>

      <v-list-item-group v-model="giftOptions" active-class="">
        <v-list-item>
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                localizedText[language].isGift
              }}</v-list-item-title>
              <v-list-item-subtitle
                >{{ localizedText[language].isGiftDetail }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-list-item>

        <v-list-item>
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title
                >{{ localizedText[language].isGiftAndAddressUnkown }}
              </v-list-item-title>
              <v-list-item-subtitle
                >{{ localizedText[language].isGiftAndAddressUnkownDetail }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>

      <div v-if="isGiftAndAddressUnkown || isGiftAndAddressIsKnown">
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>
              <v-text-field
                prepend-icon="mdi-account"
                v-model="addressModel.nameOfGiftRecipient"
                :label="localizedText[language].nameOfGiftRecipient"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>
              <v-text-field
                type="tel"
                dir="ltr"
                prepend-icon="mdi-phone"
                v-model="addressModel.phoneOfGiftRecipient"
                :prefix="language == 'en' ? country.dialCode.substring(1) : ''"
                :suffix="language == 'ar' ? country.dialCode.substring(1) : ''"
                :label="localizedText[language].phoneOfGiftRecipient"
                :rules="[rules.required, rules.numbeOfDigits]"
              >
              </v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>

      <div v-if="!isGiftAndAddressUnkown">
        <v-list-item dense v-if="country.code != 'KW'">
          <v-list-item-content>
            <v-list-item-title>
              <v-text-field
                v-model="addressModel.town"
                :label="localizedText[language].town"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>
              <!--  <v-text-field
                v-if="country.code == 'KW'"
                readonly
                v-model="addressModel.area"
                :label="localizedText[language].area"
                :rules="[rules.required]"
                @click="openAreaPicker"
              >
              </v-text-field> -->

              <v-select
                v-if="country.code == 'KW'"
                v-model="addressModel.area"
                :items="areaList"
                :label="localizedText[language].area"
              ></v-select>

              <v-text-field
                v-else
                v-model="addressModel.area"
                :label="localizedText[language].area"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense v-if="country.code != 'KW'">
          <v-list-item-content>
            <v-list-item-title>
              <v-text-field
                v-model="addressModel.postalCode"
                :label="localizedText[language].postalCode"
              >
              </v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense v-if="country.code != 'KW'">
          <v-list-item-content>
            <v-list-item-title>
              <v-text-field
                v-model="addressModel.line1"
                :rules="[rules.required]"
                :label="localizedText[language].line1"
              >
              </v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense v-if="country.code != 'KW'">
          <v-list-item-content>
            <v-list-item-title>
              <v-text-field
                v-model="addressModel.line2"
                :label="localizedText[language].line2"
              >
              </v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense v-if="country.code == 'KW'">
          <v-list-item-content>
            <v-list-item-title>
              <v-text-field
                v-model="addressModel.block"
                :label="localizedText[language].block"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense v-if="country.code == 'KW'">
          <v-list-item-content>
            <v-list-item-title>
              <v-text-field
                v-model="addressModel.street"
                :label="localizedText[language].street"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense v-if="country.code == 'KW'">
          <v-list-item-content>
            <v-list-item-title>
              <v-text-field
                v-model="addressModel.avenue"
                :label="localizedText[language].avenue"
              >
              </v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <div v-if="country.code == 'KW'">
          <v-list-item dense v-if="country.code == 'KW'">
            <v-list-item-content>
              <v-list-item-title>
                {{ localizedText[language].addressType }}
              </v-list-item-title>
              <v-list-item>
                <v-btn-toggle
                  flat
                  class="mx-auto"
                  v-model="addressModel.addressType"
                  mandatory
                  dense
                >
                  <v-btn outlined value="House">
                    {{ localizedText[language].house }}
                  </v-btn>

                  <v-btn outlined value="Apartment">
                    {{ localizedText[language].apartment }}
                  </v-btn>

                  <v-btn outlined value="Office">
                    {{ localizedText[language].office }}
                  </v-btn>
                </v-btn-toggle>
              </v-list-item>
            </v-list-item-content>
          </v-list-item>

          <v-list-item dense v-if="addressModel.addressType == 'House'">
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field
                  v-model="addressModel.house"
                  :label="localizedText[language].house"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            dense
            v-if="
              addressModel.addressType == 'Office' ||
              addressModel.addressType == 'Apartment'
            "
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field
                  v-model="addressModel.building"
                  :label="localizedText[language].building"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            dense
            v-if="
              addressModel.addressType == 'Office' ||
              addressModel.addressType == 'Apartment'
            "
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field
                  v-model="addressModel.floor"
                  :label="localizedText[language].floor"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item dense v-if="addressModel.addressType == 'Office'">
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field
                  v-model="addressModel.office"
                  :label="localizedText[language].office"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item dense v-if="addressModel.addressType == 'Apartment'">
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field
                  v-model="addressModel.flat"
                  :label="localizedText[language].flat"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </div>

      <v-list-item dense>
        <v-list-item-content>
          <v-list-item-title>
            <v-textarea
              class="my-2"
              outlined
              v-model="addressModel.deliveryInstructions"
              :label="localizedText[language].deliveryInstructions"
            ></v-textarea>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>

    <div class="ma-2" v-if="showValidationError">
      <v-alert type="error">
        {{ errorMessage }}
      </v-alert>
    </div>

    <div class="ma-2">
      <v-btn :loading="isSaving" @click="saveChanges" block dark>
        {{ localizedText[language].save }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EditAddressComponent",
props: {addressModel: Object},
  data: () => ({
    isSaving: false,
    showValidationError: false,
    errorMessage: "",
    showGiftCheckBox: false,
    isGift: false,
    giftOptions: null,
    areaList: [],
    rules: {},
    phoneRules: {},
 /*    addressModel: {
      addressName: null,
      addressType: "House",
      firstName: null,
      middleName: null,
      lastName: null,
      email: null,
      country: null,
      countryCode: null,
      town: null,
      area: null,
      postalCode: null,
      phone: null,
      secondPhone: null,
      governate: null,
      block: null,
      avenue: null,
      street: null,
      house: null,
      flat: null,
      floor: null,
      building: null,
      office: null,
      deliveryInstructions: null,
      nameOfGiftRecipient: null,
      phoneOfGiftRecipient: null,
      line1: null,
      line2: null,
      isGift: false,
      isGiftAndAddressUnkown: false,
      language: null,
      id: 0
    }, */

    localizedText: {
      en: {
        numbeOfDigits: "Number of digits must be 8",
        required: "Required",
        addressName: "Address Name",
        addressType: "Address Type",
        firstName: "First name",
        middleName: "Middle name",
        lastName: "Last name",
        phone: "Phone number",
        secondPhone: "Alternative number",
        email: "Email",
        town: "City",
        area: "Area",
        governate: "Governate",
        block: "Block",
        avenue: "Avenue",
        street: "Street",
        house: "House",
        flat: "Flat",
        floor: "Floor",
        building: "Building",
        office: "Office",
        apartment: "Apartment",
        deliveryInstructions: "Delivery Instructions",
        postalCode: "Postal Code",
        save: "Save",
        addressNameHint: "Example: My Home, My Office, ..etc",
        contactInfo: "Contact Info",
        addressDetail: "Address Details",
        phoneNumberRequired: "Please enter a valid phone number",
        firstNameRequired: "Please enter the first name",
        lastNameRequired: "Please enter the last name",
        emailRequired: "Please enter your email to receive the invoice.",
        houseRequired: "Please enter the house number.",
        areaRequired: "Please enter a valid area",
        line1Required: "Please enter Address line 1",
        townRequired: "Please enter the city",
        line1: "Address line 1",
        line2: "Address line 2 (optional)",
        nameOfGiftRecipient: "Gift recipient name",
        phoneOfGiftRecipient: "Gift recipient phone",
        isGiftAndAddressUnkown: "It's a gift & the address is unknown",
        isGiftAndAddressUnkownDetail:
          "Choose this option if you want to send a gift to a person and you only know his of her phone number and you don't know their address.",
        nameOfGiftRecipientRequired: "Gift recipient name is required",
        phoneOfGiftRecipientRequired: "Gift recipient phone is required",
        isGiftDetail:
          "With this option you know the address of the person you want to send him or her a gift",
        isGift: "It's a gift & I know the address",
      },
      ar: {
        required: "هذا الحقل مطلوب",
        numbeOfDigits: "عدد الأرقام يجب أن يكون ٨",
        addressName: "اسم العنوان",
        addressType: "نوع العنوان",
        firstName: "الاسم الأول",
        middleName: "الاسم الثاني",
        lastName: "الاسم الثاني",
        phone: "رقم الهاتف",
        secondPhone: "رقم هاتف آخر",
        email: "الايميل",
        town: "المدينة",
        area: "المنطقة",
        governate: "المحافظة",
        block: "القطعة",
        avenue: "الجادة",
        street: "الشارع",
        house: "المنزل",
        flat: "الشقة",
        floor: "الدور",
        building: "البناية",
        office: "المكتب",
        deliveryInstructions: "تعليمات اضافية",
        apartment: "شقة",
        postalCode: "الرمز البريدي",
        save: "حفظ",
        addressNameHint: "مثلا: البيت، بيت الوالد ..الخ",
        contactInfo: "معلومات الاتصال",
        addressDetail: "تفاصيل العنوان",
        phoneNumberRequired: "الرجاء كتابة رقم هاتف صحيح",
        firstNameRequired: "الرجاء كتابة الاسم الأول",
        lastNameRequired: "الرجاء كتابة اللقب (اسم العائلة)",
        emailRequired: "الرجاء كتابة الايميل للحصول على الفاتورة.",
        houseRequired: "الرجاء كتابة رقم المنزل",
        areaRequired: "الرجاء كتابة المنطقة",
        line1Required: "الرجاء كتابة السطر الأول للعنوان",
        townRequired: "الرجاء كتابة المدينة",
        line1: "السطر الأول من العنوان",
        line2: "السطر الثاني من العنوان (اختياري)",
        nameOfGiftRecipient: "اسم المهدى اليه",
        phoneOfGiftRecipient: "رقم هاتف المهدى اليه",
        isGiftAndAddressUnkown: "الطلب هدية و لا أعرف العنوان",
        isGiftAndAddressUnkownDetail:
          "قم بتحديد هذا الخيار اذا كنت تريد ارسال الطلب الى شخص لا تعرف عنوانه و فقط تعرف رقم هاتفه",
        nameOfGiftRecipientRequired: "اسم المهدى اليه مطلوب",
        phoneOfGiftRecipientRequired: "هاتف المهدى اليه مطلوب",
        isGiftDetail:
          "قم بتحديد هذا الخيار اذا كنت تعرف عنوان الشخص الذي تريد ارسال الهدية اليه",
        isGift: "الطلب هدية و أعرف العنوان",
      },
    },
  }),
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      currencyCode: "currencyCode",
      currency: "currency",
      country: "country",
    }),
    ...mapGetters("Catalog", {
      kuwaitAreas: "kuwaitAreas",
    }),
    isGiftAndAddressIsKnown: function () {
      if (this.giftOptions == 0) {
        return true;
      }

      return false;
    },
    isGiftAndAddressUnkown: function () {
      if (this.giftOptions) {
        if (this.giftOptions == 1) {
          return true;
        }
      }

      return false;
    },
  },

  methods: {
    saveChanges: async function () {
      console.log(this.giftOptions);
      if (this.validateInput() == "no") {
        this.showValidationError = true;
        setTimeout(() => (this.showValidationError = false), 6000);
        return;
      }


        this.addressModel.isGift = this.giftOptions == 0;
        this.addressModel.isGiftAndAddressUnkown = this.giftOptions == 1;
        this.addressModel.language = this.language;
        this.addressModel.countryCode = this.country.code;
        this.addressModel.country =
          this.language == "en"
            ? this.country.name.english
            : this.country.name.arabic;
        try {
          this.isSaving = true;
          await this.$store.dispatch(
            "Address/updateAddress",
            this.addressModel
          );
          this.isSaving = false;
          this.$emit("address-added", {});
        } catch (error) {
             this.isSaving = false;
          alert(error);
        }
      
    },

    validateInput: function () {
      if (!this.addressModel.phone) {
        this.errorMessage =
          this.localizedText[this.language].phoneNumberRequired;
        return "no";
      }

      if (!this.addressModel.firstName) {
        this.errorMessage = this.localizedText[this.language].firstNameRequired;
        return "no";
      }

      if (!this.addressModel.lastName) {
        this.errorMessage = this.localizedText[this.language].lastNameRequired;
        return "no";
      }

      if (!this.addressModel.email) {
        this.errorMessage = this.localizedText[this.language].emailRequired;
        return "no";
      }

      if (this.addressModel.isGiftAndAddressUnkown == true) {
        if (!this.addressModel.nameOfGiftRecipient) {
          this.errorMessage =
            this.localizedText[this.language].nameOfGiftRecipientRequired;
          return "no";
        }

        if (!this.addressModel.phoneOfGiftRecipient) {
          this.errorMessage =
            this.localizedText[this.language].phoneOfGiftRecipientRequired;
          return "no";
        }

        return "ok";
      }

      if (this.country.code == "KW") {
        if (
          !this.addressModel.house &&
          this.addressModel.addressType == "House"
        ) {
          this.errorMessage = this.localizedText[this.language].houseRequired;

          return "no";
        }
      }

      if (!this.addressModel.area || this.addressModel.area == "-") {
        this.errorMessage = this.localizedText[this.language].areaRequired;

        return "no";
      }

      if (this.country.code != "KW") {
        if (!this.addressModel.line1) {
          this.errorMessage = this.localizedText[this.language].line1Required;

          return "no";
        }

        if (!this.addressModel.town) {
          this.errorMessage = this.localizedText[this.language].townRequired;

          return "no";
        }
      }

      return "ok";
    },

    loadAreas: async function () {
      await this.$store.dispatch("Catalog/loadKuwaitAreas");
      let isEnglish = this.language == "en";
      this.areaList = this.kuwaitAreas.map((x) =>
        isEnglish ? x.name.english : x.name.arabic
      );
    },
  },
  created() {
    if (this.country.code == "KW") {
      this.rules = {
        required: (value) =>
          !!value || this.localizedText[this.language].required,
        numbeOfDigits: (value) =>
          (value && value.length == 8) ||
          this.localizedText[this.language].numbeOfDigits,
      };
    } else {
      this.rules = {
        required: (value) =>
          !!value || this.localizedText[this.language].required,
        numbeOfDigits: (value) =>
          (value && value.length >= 8) ||
          this.localizedText[this.language].numbeOfDigits,
      };
    }
  },

  async mounted() {
    await this.loadAreas();
  },
};
</script>

<style>
</style>